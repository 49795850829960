export const conf = {
    env: 'display',
    branch: 'display',
    version: '2.4',
    api: 'https://api.portal-display.com',

    esltag: {
        upload: false,
        esltags: 'Display (H)',
        esltag: 'Display Tag',
        list: 'Display List',
        add: 'Add Display',
        detail: 'Display Detail',
        autoScan: 'Auto Scan',
    },

    product: {
        products: 'Update Display (H)',
        list: 'Update Info',
        add: 'Add Product',
        detail: 'Product Detail',

        id: 'Field 1',
        name: 'Field 2',
        store: 'Site',
        price: 'Price',
        storeName: 'Store Name',
        footNote: 'Comment(s)',
    },

    binding: {
        binding: 'Display Binding',
        bindingList: 'Display Preview List',
        updateTags: 'Update Tags',
        tagId: 'Display ID',
        lot: 'Field 2',
        update: 'Preview Update',
    },

    dash: {
        totalTags: 'Total Tags',
    },
}   
